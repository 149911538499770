import clsx from 'clsx'
import { useState } from 'react'
import CommonCard from './CommonCard'

interface ProcessItemProps {
  placement: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right' | 'top' | 'bottom'
  title: string
  description?: string
  classNames?: {
    container?: string
    commonCard?: string
  }
  type?: 'text' | 'card'
}

const ProcessItem: React.FC<ProcessItemProps> = ({
  placement = 'bottom-right',
  title,
  description,
  classNames,
  type = 'card',
}) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={clsx(classNames?.container)}
    >
      <img src="/images/home/start-point.png" alt="start" width={61} height={61} />
      {isHover && (
        <img
          alt="flare"
          src="/images/home/Flare.png"
          width={250}
          height={348}
          className="absolute top-[20px] left-0 scale-[3] z-50"
        />
      )}
      {type === 'card' ? (
        <CommonCard
          title={title}
          description={description}
          className={clsx(
            'absolute',
            'w-[350px]',
            'xl:w-[426px]',
            placement === 'bottom-right' && 'left-1/2',
            placement === 'top-right' && 'left-1/2 top-0 -translate-y-full',
            placement === 'bottom-left' && 'right-1/2',
            placement === 'top-left' && 'right-1/2 top-0 -translate-y-full',
            placement === 'top' && 'left-1/2 top-0 -translate-x-1/2 -translate-y-full',
            placement === 'bottom' && 'left-1/2 bottom-0 -translate-x-1/2 translate-y-full',
            classNames?.commonCard,
          )}
        />
      ) : (
        <p
          className={clsx(
            'absolute',
            placement === 'bottom-right' && 'left-1/2',
            placement === 'top-right' && 'left-1/2 top-0 -translate-y-full',
            placement === 'bottom-left' && 'right-1/2',
            placement === 'top-left' && 'right-1/2 top-0 -translate-y-full',
            placement === 'top' && 'left-1/2 top-0 -translate-x-1/2 -translate-y-full',
            placement === 'bottom' && 'left-1/2 bottom-0 -translate-x-1/2 translate-y-full',
            classNames?.commonCard,
          )}
        >
          {title}
        </p>
      )}
    </div>
  )
}

export default ProcessItem
