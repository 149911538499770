import { useSpring, animated } from '@react-spring/web'

import HeroBanner from './components/HeroBanner'
import Domain from './components/Domain'
import ContactForm from './components/ContactForm'
import ExecutionProcess from './components/ExecutionProcess'

const Home = () => {
  const [spring] = useSpring(() => ({
    from: {
      x: -100,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
  }))

  return (
    <animated.div style={spring} className="relative">
      <HeroBanner />
      <Domain />
      <ExecutionProcess />
      <ContactForm />
    </animated.div>
  )
}

export default Home
