import clsx from 'clsx'
import { animated, useInView, useTrail } from '@react-spring/web'
import { useEffect } from 'react'

import ProcessItem from './ProcessItem'
import CommonCard from './CommonCard'

const PROCESS_ITEMS = [
  {
    title: '1. Discovery Workshop',
    description: 'Understand your business, goals, and challenges to identify opportunities for improvement.',
    key: '1',
    placement: 'bottom-right',
    className: clsx('absolute top-[120px] left-[15px]', 'xl:top-[160px] xl:left-[15px]'),
  },
  {
    title: '2. Strategy and Solution Design',
    description: 'Develop and design tailored solutions to align with your business objectives.',
    key: '2',
    placement: 'bottom-right',
    className: clsx('absolute top-[58px] left-[500px]', 'xl:top-[60px] xl:left-[500px]'),
  },
  {
    title: '3. Development',
    description: 'Build your product through structured phases: Alpha, Beta, and Release stages.',
    key: '3',
    placement: 'bottom-left',
    className: clsx('absolute top-[240px] left-[875px]', 'xl:top-[300px] xl:left-[1090px]'),
  },
  {
    title: '4. Testing and QA',
    description: 'Perform comprehensive testing and validation to ensure market readiness.',
    key: '4',
    placement: 'top-left',
    className: 'absolute top-[570px] left-[792px] xl:top-[690px] xl:left-[969px]',
  },
  {
    title: '5. Launch and Maintenance',
    description: 'Deploy your product in cloud or on-premise environments with ongoing support.',
    key: '5',
    placement: 'bottom-right',
    className: 'absolute top-[685px] left-[405px] xl:top-[820px] xl:left-[506px]',
  },
  {
    title: '6. Support and Customer Service',
    description: 'Provide continuous maintenance, product optimization, and dedicated customer support',
    key: '6',
    placement: 'top-right',
    className: 'absolute top-[615px] left-[15px] xl:top-[725px] xl:left-[35px]',
  },
]

const ExecutionProcess: React.FC = () => {
  const [imgRef, imageSpring] = useInView(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }))

  const [mobileRef, mobileInView] = useInView(() => ({
    from: { opacity: 0, x: 100 },
    to: { opacity: 1, x: 0 },
  }))

  const [trails, api] = useTrail(PROCESS_ITEMS.length, () => ({
    from: { opacity: 0, x: 100 },
    to: { opacity: 0, x: 0 },
  }))

  useEffect(() => {
    if (mobileInView) {
      api.start({
        from: { opacity: 0, x: 100 },
        to: { opacity: 1, x: 0 },
      })
    }
  }, [mobileInView])

  useEffect(() => {
    if (imageSpring) {
      api.start({
        from: { opacity: 0, x: 100 },
        to: { opacity: 1, x: 0 },
      })
    }
  }, [imageSpring])

  return (
    <div className="!mt-16 lg:!mt-36 xl:!mt-40 container lg:!px-0 flex flex-col gap-5 md:gap-8">
      <p
        className={clsx(
          'text-gradient font-semibold tracking-[-0.96px] leading-[60px] text-2xl',
          'w-fit mx-auto',
          'md:text-4xl',
          'lg:text-5xl',
        )}
      >
        Our Execution Process
      </p>
      <div ref={mobileRef} className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:hidden">
        {trails.map((trail, index) => (
          <animated.div style={trail}>
            <CommonCard
              key={PROCESS_ITEMS?.[index]?.key}
              title={PROCESS_ITEMS?.[index]?.title}
              description={PROCESS_ITEMS?.[index]?.description}
            />
          </animated.div>
        ))}
      </div>
      <div ref={imgRef} className="container hidden lg:block">
        <div className="relative lg:w-[1010px] xl:w-[1266px] h-[800px] xl:h-[950px] mx-auto">
          <div className="h-[800px] xl:h-[950px]  aspect-[2049/1092] bg-[url(/images/home/Background-circle.webp)] bg-center bg-contain bg-no-repeat absolute left-1/2 -translate-x-1/2" />
          {trails.map((trail, index) => (
            <animated.div style={trail} className={PROCESS_ITEMS?.[index]?.className}>
              <ProcessItem
                placement={PROCESS_ITEMS?.[index]?.placement as any}
                title={PROCESS_ITEMS?.[index].title}
                description={PROCESS_ITEMS?.[index].description}
              />
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExecutionProcess
