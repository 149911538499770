import CardCarousel from 'components/CardCarousel'
import { useInView, animated } from '@react-spring/web'

const PRODUCTS = [
  {
    id: 1,
    title: 'Blockchain',
    description:
      'Create innovative blockchain platforms, including NFT solutions and launchpads for early-stage token investment.',
    image: '/images/home/1.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 2,
    title: 'Mobile app',
    description: 'Build user-friendly mobile applications for both Android and iOS platforms.',
    image: '/images/home/2.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 3,
    title: 'Web app',
    description: 'Deliver professional, scalable, and secure web-based solutions.',
    image: '/images/home/3.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 4,
    title: 'CRM Services',
    description: 'Optimize customer relationship management with tailored software solutions.',
    image: '/images/home/4.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 5,
    title: 'QA & Testing',
    description: 'Ensure your product meets the highest quality standards with our rigorous testing processes.',
    image: '/images/home/5.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 6,
    title: 'AI & Machine learning',
    description:
      'Leverage AI and machine learning to transform data into actionable insights, driving smarter decision-making and innovative solutions across industries.',
    image: '/images/home/6.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 7,
    title: 'DevOps',
    description:
      'Streamline your development and operations with our DevOps services, enabling faster deployment, improved collaboration, and continuous integration.',
    image: '/images/home/7.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
  {
    id: 8,
    title: 'SaaS solution',
    description:
      'Develop powerful SaaS solutions that offer flexibility, scalability, and cost-efficiency, helping businesses deliver value through cloud-based applications.',
    image: '/images/home/8.png',
    imgClassName: 'max-w-[200px] md:max-w-[350px] xl:max-w-[400px]',
  },
]

const Domain = () => {
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 100,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }))

  return (
    <animated.div ref={ref} style={{ ...springs }} className="container mx-auto flex justify-center items-center">
      <CardCarousel items={PRODUCTS} />
    </animated.div>
  )
}

export default Domain
