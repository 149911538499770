import clsx from 'clsx'
import { useTranslation } from 'contexts/Localization'
import { useRef, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'widgets'
import Slider from 'react-slick'

interface IItem {
  id: number
  title: string
  description: string
  image: string
  imgClassName?: string
}

interface CardCarouselProps {
  items: IItem[]
  header?: (listItems: JSX.Element, currentItem?: IItem) => JSX.Element
  className?: string
}

const CardCarousel: React.FC<CardCarouselProps> = ({ items, header, className }) => {
  const slickRef = useRef<Slider>(null)
  const [activeProduct, setActiveProduct] = useState(items[0])
  const { t } = useTranslation()

  /**
   * functions
   * ====================================================================
   */

  const afterSlickChange = (index: number) => {
    setActiveProduct(items[index])
  }

  const handleActiveProduct = (index: number) => {
    slickRef.current?.slickGoTo(index)
  }

  /**
   * render view
   * ====================================================================
   */

  const listItems = (
    <div
      className={clsx(
        'flex items-center gap-1 overflow-y-auto scrollbar-hide',
        'md:flex-wrap md:gap-3 md:justify-center',
        'xl:gap-5',
      )}
    >
      {items?.map((item, index) => (
        <button
          type="button"
          onClick={() => handleActiveProduct(index)}
          className={clsx(
            'rounded-full px-4 py-[6px] flex justify-center items-center',
            'backdrop-blur text-[#F1F1F1] font-semibold whitespace-nowrap cursor-pointer',
            item?.id === activeProduct?.id && 'tw-btn text-gradient',
            'md:text-lg',
          )}
          key={item?.id}
        >
          {t(item?.title)}
        </button>
      ))}
    </div>
  )

  return (
    <div
      className={clsx(
        'w-full aspect-video bg-[url(/images/bg/domain-bg.webp)]',
        'border-solid border border-[#292929] rounded-lg',
        'bg-center bg-cover bg-no-repeat gap-9',
        'max-w-[1150px] p-3 flex flex-col',
        'md:px-9 md:py-8',
        'lg:px-12 lg:py-11',
        'xl:gap-16',
        className,
      )}
    >
      {header ? header(listItems, activeProduct) : listItems}

      <Slider
        ref={slickRef}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        autoplay
        afterChange={afterSlickChange}
      >
        {items?.map((item) => (
          <div className="!flex justify-center">
            <img
              key={item?.id}
              src={item?.image}
              alt={item?.title}
              className={clsx(
                'aspect-square object-contain max-w-[150px]',
                'md:max-w-[300px]',
                'lg:max-w-[350px]',
                item?.imgClassName,
              )}
            />
          </div>
        ))}
      </Slider>
      <p
        className={clsx(
          'font-semibold text-[#ADADAD] text-center max-w-[800px] mx-auto text-xs',
          'md:text-sm',
          'lg:text-base',
        )}
      >
        {t(activeProduct?.description)}
      </p>
    </div>
  )
}

export default CardCarousel

const NextArrow = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx('tw-btn !p-[6px] absolute right-0 top-1/2 -translate-y-1/2')}
    >
      <ArrowRight />
    </button>
  )
}
const PrevArrow = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx('tw-btn !p-[6px] absolute left-0 top-1/2 -translate-y-1/2 z-10')}
    >
      <ArrowLeft />
    </button>
  )
}
