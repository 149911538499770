import clsx from 'clsx'

interface CommonCardProps {
  title: string
  description: string
  className?: string
}

const CommonCard: React.FC<CommonCardProps> = ({ title, description, className }) => {
  return (
    <div
      className={clsx(
        'flex flex-col gap-2 items-start',
        'bg-[url(/images/home/star-bg.png)] rounded-lg border-solid border border-[#292929]',
        'bg-cover bg-center bg-no-repeat p-3',
        'xl:p-5',
        className,
      )}
    >
      <p className="text-gradient text-lg font-semibold whitespace-nowrap">{title}</p>
      <p className="text-sm text-[#ADADAD]">{description}</p>
    </div>
  )
}

export default CommonCard
