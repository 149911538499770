import { Form, Input } from 'antd'
import clsx from 'clsx'
import styled from 'styled-components'
import { ChatsCircle, EnvelopeSimple, MapPin, Money, Phone, UserCircle } from 'widgets'
import { animated, useInView } from '@react-spring/web'

const { TextArea } = Input

const ContactForm: React.FC = () => {
  const [ref, spring] = useInView(() => ({
    from: { opacity: 0, y: 100 },
    to: { opacity: 1, y: 0 },
  }))

  return (
    <animated.div
      ref={ref}
      style={spring}
      className={clsx(
        'bg-[url(/images/bg/end-bg.webp)] w-full',
        'bg-bottom bg-cover bg-no-repeat pt-16 lg:pt-36 pb-10',
        'xl:py-28',
      )}
    >
      <div className={clsx('container flex flex-col justify-center items-center gap-3', 'md:gap-8')}>
        <p
          className={clsx(
            'text-gradient font-semibold tracking-[-0.96px] leading-[60px] text-2xl',
            'md:text-4xl',
            'lg:text-5xl',
          )}
        >
          Tell Us About Your Project
        </p>
        <div
          className={clsx(
            'w-full bg-[url(/images/bg/domain-bg.webp)]',
            'border-solid border border-[#292929] rounded-lg',
            'bg-center bg-cover bg-no-repeat gap-9',
            'max-w-[1150px] p-3 py-5 flex flex-col',
            'md:px-24 md:py-14',
            '',
          )}
        >
          <Form className="flex flex-col ">
            <div className="grid lg:grid-cols-2 gap-x-6 gap-y-1 lg:gap-y-2">
              <Form.Item>
                <CustomInput prefix={<UserCircle className="text-2xl text-[#F1F1F1]" />} placeholder="Your name*" />
              </Form.Item>
              <Form.Item>
                <CustomInput
                  prefix={<EnvelopeSimple className="text-2xl text-[#F1F1F1]" />}
                  placeholder="Email address*"
                />
              </Form.Item>
              <Form.Item>
                <CustomInput prefix={<Phone className="text-2xl text-[#F1F1F1]" />} placeholder="Phone number*" />
              </Form.Item>
              <Form.Item>
                <CustomInput prefix={<Money className="text-2xl text-[#F1F1F1]" />} placeholder="Your budget*" />
              </Form.Item>
              <Form.Item>
                <CustomInput prefix={<MapPin className="text-2xl text-[#F1F1F1]" />} placeholder="Country*" />
              </Form.Item>
              <Form.Item>
                <CustomInput
                  prefix={<ChatsCircle className="text-2xl text-[#F1F1F1]" />}
                  placeholder="Skype ID/ WhatsApp No"
                />
              </Form.Item>
              <Form.Item className="lg:col-span-2">
                <CustomTextArea placeholder="Project description" rows={5} />
              </Form.Item>
            </div>

            <button
              type="submit"
              className="tw-btn text-gradient text-lg font-semibold self-center mt-5 lg:mt-10 xl:mt-24"
            >
              Submit form
            </button>
          </Form>
        </div>
      </div>
    </animated.div>
  )
}

export default ContactForm

const CustomInput = styled(Input)`
  padding: 10px 16px;
  font-weight: 500;
  border-radius: 60px;
  border-width: 1.5px;
  border-color: #919191;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`

const CustomTextArea = styled(TextArea)`
  padding: 10px 16px;
  font-weight: 500;
  border-radius: 24px;
  border-width: 1.5px;
  border-color: #919191;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`
